export const whoContacted = [
  {
    label: 'Credor',
    amount: 3,
  },
  {
    label: 'Advogado',
    amount: 3,
  },
  {
    label: 'Cônjuge',
    amount: 3,
  },
  {
    label: 'Filhos',
    amount: 2,
  },
  {
    label: 'Responsável pelo credor',
    amount: 4,
  },
  {
    label: 'Outros',
    amount: 1,
  },
];
export const thermometer = [
  { label: 'Frio', amount: 0 },
  { label: 'Morno', amount: 1 },
  { label: 'Quente', amount: 2 },
];

export const plans = [
  { label: 'Automóveis', amount: 0 },
  { label: 'Dívidas', amount: 1 },
  { label: 'Familiares', amount: 0 },
  { label: 'Investir', amount: 2 },
  { label: 'Moradia', amount: 3 },
  { label: 'Não perguntei', amount: 0 },
  { label: 'Não tem plano', amount: 5 },
  { label: 'Outros bens', amount: 0 },
  { label: 'Quando perguntei, não quis falar', amount: 6 },
  { label: 'Saúde', amount: 3 },
];

export const urgencyPlans = [
  { label: 'Não é prioridade', amount: 0 },
  { label: 'Quer realizar em até 1 mês', amount: 1 },
  { label: 'Quer realizar em até 3 meses', amount: 2 },
  { label: 'Quer realizar em até 6 meses', amount: 3 },
];

export const reasonsN1 = [
  { label: 'N1A Desligamento Imediato', amount: 0 },
  { label: 'N1B Não trata com terceiros', amount: 1 },
  { label: 'N1C Informação Incorreta/Dúvida sobre o Processo', amount: 2 },
];

export const reasonN2 = [
  { label: 'N2A Sem Conhecimento/Não tomadores de decisão', amount: 1 },
  { label: 'N2B Sem Conhecimento Prévio (Desatualizados)', amount: 2 },
  { label: 'N2C Indisponíveis/Ocupados', amount: 3 },
];

export const reasonsN3 = [
  { label: 'N3A Aguardando Esclarecimento Advogado', amount: 0 },
  { label: 'N3B Desconfiança de Golpe', amount: 1 },
  { label: 'N3C Apego ao Deságio', amount: 2 },
];

export const reasonsN4 = [
  {
    label: 'N4A Desinteresse (Aguardando Pagamento do Governo)',
    amount: 0,
  },
  { label: 'N4B Desinteresse (Estabilidade Financeira)', amount: 1 },
  { label: 'N4C Desinteresse (Contrato/Compromisso firmado)', amount: 2 },
  { label: 'N4D Interesse (Analisando Proposta)', amount: 2 },
];

export const meansConnection = [
  { label: 'SMS', amount: 3 },
  { label: 'RCS', amount: 3 },
  { label: 'Bing', amount: 3 },
  { label: 'E-mail', amount: 4 },
  { label: 'Facebook', amount: 4 },
  { label: 'Instagram', amount: 3 },
  { label: 'Google', amount: 5 },
  { label: 'Youtube', amount: 5 },
  { label: 'Site', amount: 5 },
  { label: 'WhatsApp', amount: 3 },
  { label: 'WhatsApp Automático', amount: 3 },
  {
    label: 'Telefone',
    amount: 2,
  },
  { label: 'Broker', amount: 0 },
  { label: 'Advogado Parceiro', amount: 0 },
  { label: 'Indicação', amount: 0 },
  { label: 'Ura Reversa', amount: 0 },
  { label: 'Outros', amount: 1 },
];

export const profileOptions = [
  { label: 'Direto', amount: 0 },
  { label: 'Educado', amount: 0 },
  { label: 'Grosso', amount: 0 },
  { label: 'Instruído', amount: 0 },
  { label: 'Simples', amount: 0 },
  { label: 'Conversador', amount: 0 },
];

export const objections = [
  { label: 'Interferência', amount: 0 },
  { label: 'Desconfiança', amount: 0 },
  { label: 'Acesso aos dados', amount: 0 },
  { label: 'Valor de proposta', amount: 0 },
  { label: 'Não se recorda da ação', amount: 0 },
  { label: 'Não tem urgência em resolver (pode aguardar)', amount: 0 },
  { label: 'Não acredita na nossa solução', amount: 0 },
  { label: 'Não deu abertura para conversar', amount: 0 },
  { label: 'Prefere outra empresa', amount: 0 },
];

export const yesOrNo = [
  { label: 'Sim', amount: 1 },
  { label: 'Não', amount: 0 },
];

export const yesOrNoOrNA = [
  { label: 'Sim', amount: 0 },
  { label: 'Não', amount: 0 },
  { label: 'Não se aplica', amount: 0 },
];

export const bothered = [
  { label: 'Sim', amount: 1 },
  { label: 'Não', amount: 0 },
  { label: 'Não tentei extrair o sentimento do credor', amount: 0 },
];

export const influences = [
  { label: 'Advogado', amount: 0 },
  { label: 'Cônjuge', amount: 1 },
  { label: 'Filhos', amount: 2 },
  { label: 'Terceiros', amount: 3 },
  { label: 'Outros (Nota)', amount: 4 },
];
export const optIn = [
  { label: 'Sim', amount: 0 },
  { label: 'Não', amount: 0 },
  { label: 'Não perguntei', amount: 0 },
];

export const contact_competition = [
  { label: 'Sim', amount: 0 },
  { label: 'Não', amount: 0 },
  { label: 'Não se aplica', amount: 0 },
];
