import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  AiOutlineCopy,
  AiOutlineDownload,
  AiOutlineSave,
} from 'react-icons/ai';
import Swal from 'sweetalert2';
import { add } from 'date-fns';
import api from '../../services/api';
import { Container } from '../../components/Container';
import {
  Separator,
  DivSeparator,
  ContainerButton,
  DivInputHubSpot,
  InputID,
  ProposalButton,
  H2,
  Button1,
  Button2,
  DivHeader,
  LabelHubspot,
  DivIdCnx,
  InputCnx,
  DivComissionStaggered,
  ComissionStaggeredContainer,
  Button3,
  Input,
  InputInfos,
  DivInfos,
} from './styles';

import { Title } from '../../components/Styles/Title';
import { Body } from '../../components/Styles/Body';
import {
  Navegation,
  H1Navigation,
  H1NavigationNow,
} from '../../components/Styles/Navigation';
import { ModalFormInformation } from './ModalFormInformation';
import { useAuth } from '../../hooks/auth';
import apiChatbot from '../../services/apiChatbot';
import { IOnlineProposalDTO } from './dtos/IOnlineProposalDTO';
import { InformationLender } from './components/InformationLender';
import { InformationProcess } from './components/InformationProcess/index';
import { BaseValues } from './components/BaseValues';
import { ReturnValuesComponent } from './components/ReturnValuesComponent';
import { StaggeredProposalComponent } from './components/StaggeredProposalComponent';
import { Commission } from './components/Commission';
import { IResponse, IResponseLoan } from './dtos/IResponse';
import { IStaggeredProposal } from './dtos/IStaggeredProposal';
import { IHubspotDeal } from './dtos/IHubspotDeal';
import { LoanModal } from './components/LoanModal';
import { ConectionInformation } from './components/ConectionInformation';

const Calculator: React.FC = () => {
  const { user } = useAuth();
  const [openFormInformationModal, setOpenFormInformationModal] =
    useState(false);
  const [deal, setDeal] = useState<IHubspotDeal>({} as IHubspotDeal);

  const [hubspotDealId, setHubspotDealId] = useState<string>('');
  const [openLoanModal, setOpenLoanModal] = useState<boolean>(false);

  const [returnValues, setReturnValues] = useState<IResponse>({} as IResponse);

  const [staggeredValues, setStaggeredValues] = useState<IStaggeredProposal>(
    {} as IStaggeredProposal,
  );

  const [proposalPercentage, setProposalPercentage] = useState('');

  const handleOpenFormInformationModal = useCallback(() => {
    setOpenFormInformationModal(true);
  }, []);

  const handleCloseFormInformationModal = useCallback(() => {
    setOpenFormInformationModal(false);
  }, []);

  const handleOpenLoanModa = useCallback(() => {
    setOpenLoanModal(true);
  }, []);

  const handleCloseLoanModa = useCallback(() => {
    setOpenLoanModal(false);
  }, []);

  const handleResetDeal = useCallback(() => {
    setProposalPercentage('');

    setDeal({
      age: '',
      birth_date: '',
      cpf: '',
      date_base: '',
      dealname: '',
      debitor: '',
      expedition_date: '',
      highlighted_honorary: '',
      honorary: '',
      income: '',
      judicial_district: '',
      lawyer_name: '',
      laywer_type: '',
      loa: '',
      nature: '',
      number_of_months: '',
      pss: '',
      quantity_in_main_process: '',
      reason: '',
      sms_type: '',
      start_of_process: '',
      system_of_process: '',
      trf: '',
      tj: '',
      updated_pss: '',
      updated_value: '',
      updated_value_ipcae: '',
      value: '',
      value_currency: '',
      customer_main_value: '',
      lawyer_main_value: '',
      total_purchase: '',
      city: '',
      connection_method: '',
      filiation: '',
      loa_value: '',
      possible_buy: '',
      product: '',
      proposalVariation: '',
      main_value: '',
    } as IHubspotDeal);
  }, []);

  const createScheduleError = useCallback((message: string) => {
    Swal.fire('Atenção!', message, 'warning');
  }, []);

  const handleAlertPossibleBuy = useCallback(
    (possibleBuy: string) => {
      if (possibleBuy === 'Não') {
        createScheduleError(
          'CAMPO PASSÍVEL DE COMPRA PREENCHIDO COMO NÃO. CONFERIR NO HUBSPOT - NÃO É POSSIVEL A COMPRA!',
        );
      }
    },
    [createScheduleError],
  );

  // engloba a handleDealChange e a handlePercentageInputChange
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'honorary') {
      deal.honorary = value;
      setDeal({ ...deal });
    }
    if (name === 'porcentagem') {
      setProposalPercentage(value);
    }
  };

  const handleHubspotDealIdChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value.length === 10 || value.length === 11) {
        setHubspotDealId(value);

        await api
          .get(`/negociations/calculations/new?hubspot_deal_id=${value}`)
          .then(response => {
            handleResetDeal();

            setDeal(response.data);

            setReturnValues({
              grossValue: '',
              feesValue: '',
              irraValue: '',
              percentageIncomeTax: '',
              pssValue: '',
              liquidValue: '',
              proposalValue: '',
              economyComission: '',
              desagyComission: '',
              totalComission: '',
              proposalVariation: '',
              superpreference: '',
              sucbential_honorary: '',
              special_table: '',
              irraValueRRA: '',
              liquidValueRRA: '',
              percentageIncomeTaxRRA: '',
              proposalValueRRA: '',
              proposalPercentageRRA: '',
              loan: { loanData: [] } as IResponseLoan,
              lastProposalValue: '',
              percentLastProposal: '',
              percentProposalInput: '',
              rraCelling: '',
              tableCelling: '',
            });

            setStaggeredValues({
              proposalDez: '',
              proposalJun: '',
              splitDez: '',
              splitJun: '',
            });

            handleAlertPossibleBuy(response.data.possible_buy);
          });
      }
    },
    [handleAlertPossibleBuy, handleResetDeal],
  );

  function copy() {
    const name = `Nome do credor: ${deal.dealname ? deal.dealname : ''}`;
    const cpf = `CPF: ${deal.cpf ? deal.cpf : ''}`;

    const date = `Data de expedição: ${
      deal.expedition_date ? deal.expedition_date : ''
    }`;
    const to = `Contra quem: ${deal.debitor ? deal.debitor : ''}`;
    const reason = `Motivo: ${deal.reason ? deal.reason : ''}`;
    const stick = `Vara: ${
      deal.judicial_district ? deal.judicial_district : ''
    }`;

    const lawyer = `Advogado: ${deal.lawyer_name ? deal.lawyer_name : ''}`;
    const fees = `Honorários contratuais: ${
      deal.honorary ? deal.honorary : ''
    }%`;

    const dateBase = `Data base: ${deal.date_base ? deal.date_base : ''}`;
    const valueIssue = `Valor ofício expedido: ${
      deal.value_currency ? deal.value_currency : ''
    }`;

    const valueUpdate = `Valor bruto atualizado: ${
      returnValues.grossValue ? returnValues.grossValue : ''
    }`;
    const valueFees = `Valor do honorário: ${
      returnValues.feesValue ? returnValues.feesValue : ''
    }`;
    const ir = `Imposto de renda: ${
      deal.product === 'FEDERAL'
        ? returnValues.irraValueRRA
          ? returnValues.irraValueRRA
          : ''
        : returnValues.irraValue
    }`;
    const percent_ir = `IR %: ${
      deal.product === 'FEDERAL'
        ? returnValues.percentageIncomeTaxRRA
          ? returnValues.percentageIncomeTaxRRA
          : ''
        : returnValues.percentageIncomeTax
    }%`;
    const pssCopy = `PSS: ${
      returnValues.pssValue ? returnValues.pssValue : ''
    }`;
    const valuePrecatory = `Valor precatório líquido: ${
      deal.product === 'FEDERAL'
        ? returnValues.liquidValueRRA
          ? returnValues.liquidValueRRA
          : ''
        : returnValues.liquidValue
    }`;
    const proposalValue = `Valor proposta: ${
      returnValues.proposalValue ? returnValues.proposalValue : ''
    }`;
    const percentProposal = `Porcentagem proposta: ${
      deal.product === 'FEDERAL'
        ? returnValues.proposalPercentageRRA
          ? returnValues.proposalPercentageRRA
          : ''
        : proposalPercentage || ''
    }%`;

    const textToCopy = `
    \n ${name} \n ${cpf}
    \n ${date} \n ${to} \n ${reason} \n ${stick}
    \n ${lawyer} \n ${fees}
    \n ${dateBase} \n ${valueIssue}
    \n ${valueUpdate} \n ${valueFees} \n ${ir} \n ${percent_ir}  \n ${pssCopy} \n ${valuePrecatory} \n ${proposalValue} \n ${percentProposal}
    `;
    const elements = document.createElement('textarea');
    elements.value = textToCopy;
    document.body.appendChild(elements);
    elements.select();
    document.execCommand('copy');
    document.body.removeChild(elements);
  }

  function imprimir() {
    window.print();
  }

  const phoneFormatter = celular => {
    celular = celular.replace(/\D/g, ''); // Remove todos os caracteres que não são dígitos

    if (celular.length === 0) {
      return '';
    }
    if (celular.length <= 2) {
      // Formato inicial
      return `(${celular}`;
    }
    if (celular.length <= 6) {
      // Formato com DDD
      return `(${celular.slice(0, 2)}) ${celular.slice(2)}`;
    }
    if (celular.length <= 10) {
      // Formato com DDD e hífen
      return `(${celular.slice(0, 2)}) ${celular.slice(2, 6)}-${celular.slice(
        6,
      )}`;
    }

    return `(${celular.slice(0, 2)}) ${celular.slice(2, 6)}-${celular.slice(
      6,
      10,
    )}`;
  };

  const [phoneNumber, setPhoneNumber] = useState('');

  function convertMoneyStringToFloat(string: string): number {
    // Remove o símbolo "R$" e espaços em branco da string
    string = string.replace(/R\$\s*/, '');

    // Remove os pontos de milhar da string
    string = string.replace(/\./g, '');

    // Substitui a vírgula decimal por ponto
    string = string.replace(',', '.');

    // Converte a string em um valor float
    const valorFloat = parseFloat(string);

    return isNaN(valorFloat) ? null : valorFloat;
  }

  const createProposalModal = useCallback(() => {
    Swal.fire({
      title: 'Gerar Proposta',
      html: `${
        '<div style="display: flex; flex-direction: column;">' +
        '<label for="swal-input1" style="align-self: flex-start;">Tempo de Expiração</label>' +
        '<input id="swal-input1" class="swal2-input" type="number" placeholder="Dias" style="min-width: 100%" min="2" />' +
        '<label for="swal-input2" style="align-self: flex-start;">Celular Closer</label>' +
        '<input id="swal-input2" class="swal2-input" maxLength="15"  placeholder="(31) 3816-0000" value='
      }${phoneNumber} ></div>`,

      focusConfirm: false,
      inputValidator: inputValue => {
        return null;
      },
      didOpen: () => {
        const input2 =
          Swal.getHtmlContainer().querySelector<HTMLInputElement>(
            '#swal-input2',
          );

        input2.addEventListener('input', () => {
          input2.value = phoneFormatter(input2.value);
        });
      },
      preConfirm: () => [
        document.querySelector<HTMLInputElement>('#swal-input1').value,
        document.querySelector<HTMLInputElement>('#swal-input2').value,
      ],
      confirmButtonText: 'Salvar Proposta',
    }).then(result => {
      if (!result.isConfirmed) return;

      const daysToExpire = result.value[0];
      const closerPhone = result.value[1].replace(/\D/g, '');

      const daysToExpireParsed = parseInt(daysToExpire, 10);

      const dataExpiracao = isNaN(daysToExpireParsed)
        ? null
        : new Date().setDate(new Date().getDate() + daysToExpireParsed);

      // const [, , expeditionYear] = deal.expedition_date.split('/');

      const [, deadLineYear] = deal.loa.split('LOA ');

      const deadLine = deal.receipt_deadline
        ? deal.receipt_deadline
        : `${Number(deadLineYear)}-12-01`;

      // const proposalParams = {
      //   nome: deal.dealname,
      //   valor_proposta: convertMoneyStringToFloat(returnValues.proposalValue),
      //   valor_bruto: convertMoneyStringToFloat(returnValues.grossValue),
      //   valor_liquido: convertMoneyStringToFloat(returnValues.liquidValueRRA),
      //   valor_honorario: convertMoneyStringToFloat(returnValues.feesValue),
      //   valor_pss: convertMoneyStringToFloat(returnValues.pssValue),
      //   valor_imposto_renda: convertMoneyStringToFloat(returnValues.irraValue),
      //   trf: deal.trf ? deal.trf : deal.tj,
      //   vara: deal.judicial_district,
      //   ente_devedor: deal.debitor,
      //   inicio_processo: deal.start_of_process,
      //   data_expedicao: deal.expedition_date,
      //   loa: deal.loa,
      //   motivo: deal.reason,
      //   advogado: deal.lawyer_name,
      //   data_expiracao: dataExpiracao,
      //   nome_closer: user.name,
      //   closer_phone: closerPhone,
      //   closer_user_id: user.id,
      //   hubspot_deal_id: hubspotDealId,
      //   hubspot_email: deal.email,
      // };

      const proposalParams = {
        proposal_value: returnValues.proposalValue.replace(/R\$\s*/, ''),
        gross_value: returnValues.grossValue.replace(/R\$\s*/, ''),
        liquid_value: returnValues.liquidValueRRA.replace(/R\$\s*/, ''),
        fees_value: returnValues.feesValue.replace(/R\$\s*/, ''),
        income_taxes:
          convertMoneyStringToFloat(returnValues.irraValueRRA) +
          convertMoneyStringToFloat(returnValues.pssValue),
        expiration_date: dataExpiracao,
        hubspot_deal_id: hubspotDealId,
        closer_phone: closerPhone,
        deadline: deadLine,
        receipt_days: '5 a 10',
        cpf: deal.cpf,
        creditor_name: deal.dealname,
        loa: deal.loa,
      };

      const notEmpty = Object.keys(proposalParams).every(key => {
        if (
          proposalParams[key] === null ||
          proposalParams[key] === '' ||
          proposalParams[key] === undefined
        ) {
          Swal.fire({
            title: 'Não é possível gerar Proposta',
            html: `O campo: <b>${key.replace(
              '_',
              ' ',
            )} esta vazio</b> e deve estar preenchido para poder gerar uma proposta`,
          });
          return false;
        }

        return true;
      });

      if (!notEmpty) return;

      api
        .post<{
          proposal_file_link: string;
          proposal_access_code: string;
        }>('/negociations/calculations/create-proposal', proposalParams)
        .then(requestResult => {
          if (requestResult.status === 201) {
            const proposta = requestResult.data;

            Swal.fire({
              title: 'Proposta Gerada Com Sucesso!',
              html: `<div style="display: flex; flex-direction: column;">
                <a style="align-self: center;" target="_blank" href="${proposta.proposal_file_link}">
                  <button class="swal2-confirm swal2-styled">Baixar arquivo</button>
                </a>
              </div>`,
              // html: `<div style="display: flex; flex-direction: column;">
              //   <div style="align-self: flex-start;"><b>Acesso do credor:</b></div>
              //   <span style="align-self: flex-start;">CPF: ${deal.cpf}</span>
              //   <span style="align-self: flex-start;">Código: ${proposta.proposal_access_code}</span>
              //   <div style="align-self: flex-start;"><b>Link de acesso:</b></div>
              //   <a style="align-self: flex-start;" href="https://proposta.precato.com.br/">https://proposta.precato.com.br/</a>

              // </div>`,
              // confirmButtonText: `
              //    <a class="swal2-confirm swal2-styled" style="align-self: center;" target="_blank" href="${proposta.proposal_file_link}">
              //     Baixar arquivo
              //   </a>`,
              // cancelButtonText: 'Ok',
              // cancelButtonColor: '#d33',
              // showCancelButton: true,
            });
          } else {
            Swal.fire({
              title: 'Erro!',
              text: 'Ocorreu um erro no servidor ao tentar gerar a proposta online!',
            });
          }
        })
        .catch(() => {
          Swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro ao tentar gerar a proposta online!',
          });
        });
    });
  }, [
    deal.expedition_date,
    deal.receipt_deadline,
    hubspotDealId,
    phoneNumber,
    returnValues.feesValue,
    returnValues.grossValue,
    returnValues.irraValue,
    returnValues.liquidValueRRA,
    returnValues.proposalValue,
    returnValues.pssValue,
  ]);

  const staggeredProposalValidate = () => {
    if (
      user.profile.includes('Gestor') ||
      user.profile.includes('Liderança Comercial') ||
      user.profile.includes('Gestao') ||
      user.profile.includes('Gestor Operação Comercial') ||
      user.profile.includes('Calculadora') ||
      user.profile.includes('Administrador') ||
      user.id === '7be65add-3f06-4e13-90ce-ab248d454e2a'
    ) {
      if (deal.partner_fund === 'PR' || !deal.partner_fund) return true;
    }
    return false;
  };

  return (
    <Container>
      <Navegation>
        <H1Navigation>Negociação {'>'}</H1Navigation>
        <H1NavigationNow>Calculadora</H1NavigationNow>
      </Navegation>

      <Body>
        <Title>Sistema de Cálculos</Title>
        <DivSeparator>
          <Separator />
        </DivSeparator>
        <DivHeader className="two">
          <DivIdCnx>
            <DivInputHubSpot>
              <LabelHubspot>
                ID Hubspot
                <InputID
                  id="text-id"
                  name="textId"
                  onChange={handleHubspotDealIdChange}
                  placeholder="ID Hubspot"
                />
              </LabelHubspot>
            </DivInputHubSpot>
          </DivIdCnx>
          {user.profile.includes('Closer') ? (
            <ProposalButton
              type="button"
              onClick={handleOpenFormInformationModal}
            >
              Solicitar aprovação
            </ProposalButton>
          ) : (
            ''
          )}

          {/* <Link to="/negociations/calculator/settings">
            <ButtonConfiguration type="button" disabled>
              Configuração
            </ButtonConfiguration>
          </Link> */}
        </DivHeader>
        {user.profile !== 'Calculadora broker' &&
          user.profile !== 'Calculadora' &&
          user.profile !== 'Calculadora Closer' && (
            <>
              <H2>Informações de conexão</H2>
              <ConectionInformation deal={deal} />
            </>
          )}

        <H2>Informações sobre o credor</H2>

        <InformationLender
          deal={deal}
          openLoan={handleOpenLoanModa}
          brokerClient={false}
        />

        <H2>Informações sobre o processo</H2>

        <InformationProcess deal={deal} />

        <H2>Valores base</H2>

        <BaseValues
          deal={deal}
          returnValues={returnValues}
          userProfile={user.profile}
          brokerClient={false}
        />

        <H2>Valor do Retorno</H2>

        <ReturnValuesComponent
          dealId={hubspotDealId}
          setReturnValues={setReturnValues}
          deal={deal}
          proposalPercentage={proposalPercentage}
          handleChange={handleChange}
          returnValues={returnValues}
          openLoan={handleOpenLoanModa}
          brokerClient={false}
        />

        {/*   {staggeredProposalValidate() && (
          <>
            <ComissionStaggeredContainer>
              <DivComissionStaggered>
                <H2>Proposta escalonada</H2>

                <StaggeredProposalComponent
                  deal={deal}
                  staggeredValues={staggeredValues}
                  setStaggeredValues={setStaggeredValues}
                  hubspotDealId={hubspotDealId}
                  proposalPercentage={proposalPercentage}
                />
              </DivComissionStaggered>

              <DivComissionStaggered>
                <H2>Comissão</H2>
                <Commission returnValues={returnValues} />
              </DivComissionStaggered>
            </ComissionStaggeredContainer>
          </>
        )} */}

        {user.profile !== 'Hunter New' &&
          user.profile !== 'Hunter Líder' &&
          user.profile !== 'Hunter Teste' &&
          user.profile !== 'Gestores Hunter New' &&
          user.profile !== 'Calculadora' &&
          user.profile !== 'Calculadora Closer' && (
            <>
              <H2>Comissão</H2>
              <Commission returnValues={returnValues} />
            </>
          )}
        {user.profile !== 'Calculadora broker' &&
          user.profile !== 'Calculadora' &&
          user.profile !== 'Calculadora Closer' && (
            <>
              <ContainerButton>
                <Button1 type="button" onClick={imprimir}>
                  <AiOutlineDownload />
                  Imprimir
                </Button1>
                <Button2 type="button" onClick={copy}>
                  <AiOutlineCopy />
                  Copiar
                </Button2>
              </ContainerButton>
              <ContainerButton>
                <Button3 type="button" onClick={createProposalModal}>
                  <AiOutlineSave /> Gerar Proposta em PDF
                </Button3>
              </ContainerButton>
            </>
          )}

        <ModalFormInformation
          isOpen={openFormInformationModal}
          onRequestClose={handleCloseFormInformationModal}
        />
        <LoanModal
          isOpen={openLoanModal}
          onRequestClose={handleCloseLoanModa}
          loanData={returnValues.loan}
        />
      </Body>
    </Container>
  );
};

export default Calculator;
